.nav {
    background-color: black;
    color: white;
    height: 50px;
    display: flex;
    align-items: center; /* Centers the content vertically */
    justify-content: space-between; /* Adjust this as needed */
    /* Add other styles for your nav bar here (background, padding, etc.) */
}

.ul {
    list-style: none;
    margin: 0;
    display: flex; /* Makes the list horizontal */
    align-items: center; /* Align list items vertically */
}

.li {
    /* Style your list items here */
}

.ul li a {
    color: white; /* Sets the text color to white */
    display: block;
    padding: 10px; /* Adjust padding as needed for spacing */
    text-decoration: none; /* Removes the underline */
    transition: color 0.5s;
    /* Add other styles for your links */
    
}



.borderBottom a:hover{
    color: red; /* Changes the background to red on hover */
    border-bottom: 4px solid red;
}

.logo {
    height: 37px;
    width: 151PX;
    pointer-events: none;
}





